import {CommonModule, isPlatformBrowser} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-create-tienda-banner',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './create-tienda-banner.component.html',
	styleUrls: ['./create-tienda-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTiendaBannerComponent implements OnInit {
	private key = 'createTiendaBanner';
	private getBannerValue = () => window.localStorage.getItem(this.key);
	private setBannerValue = (value: string) => window.localStorage.setItem(this.key, value);
	private cd = inject(ChangeDetectorRef);
	private router = inject(Router);
	@Input() modo: 'mobile' | 'desktop' = 'desktop';
	displayBanner!: boolean;
	private platformId = inject(PLATFORM_ID);

	constructor() {}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.displayBanner = this.getBannerValue() !== 'true';
			this.cd.detectChanges();
		}
	}

	closeBanner() {
		this.setBannerValue('true');
		this.displayBanner = false;
		this.cd.detectChanges();
	}

	goToCreateTienda() {
		this.closeBanner();
		this.router.navigate(['tienda/crear']);
	}
}
