import {Injectable} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {Negocio} from 'interfaces/negocio.interface';
import {BehaviorSubject, Observable, first, map} from 'rxjs';
import {AuthService} from './auth.service';
import {User as FirebaseUser} from '@angular/fire/auth';
import {where} from '@angular/fire/firestore';
import {User} from 'interfaces/user.interface';
@Injectable({
	providedIn: 'root'
})
export class UserService {
	userCrendetials: any = null;
	user!: any;
	negocio!: Negocio;
	/**Usuario de firebase */
	firebaseUser!: FirebaseUser | null;

	//prettier-ignore
	constructor(
    private authService: AuthService,
    private helpers: HelperService
  ) {}

	public initUser() {
		return this.authService
			.getUser$()
			.pipe(first())
			.pipe(
				map((v) => {
					this.firebaseUser = v;
					return v;
				})
			);
	}

	public clearAll() {
		//this.firebaseUser = null;
	}

	public getUserUid(): Promise<string> {
		return this.authService.getUser().then((user) => (user ? user.uid : ''));
	}

	/**
	 * Obtiene informacion del usuario logueado.
	 * @returns
	 */
	public getUserInfo = (): Observable<FirebaseUser | null> => this.authService.getUser$();

	/**
	 * Obtiene la información del usuario logueado y lo busca en Firebase.
	 * @returns
	 */
	public getUserInfoFirebase = (): Promise<User | undefined> =>
		this.authService
			.getUser()
			.then(
				async (user: FirebaseUser | null): Promise<User | undefined> =>
					user ? await this.helpers.getInfo('users', 'all', where('uid', '==', user?.uid)).then((users: User[]) => users[0]) : undefined
			);

	/**
	 * Cierra la sesion del usuario.
	 * @returns
	 */
	public cerrarSesion = async (): Promise<void> => await this.authService.logout().then(() => this.clearAll());
}

