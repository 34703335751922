import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {where} from '@angular/fire/firestore';
import {HelperService} from 'helpers/helper.service';
import {Negocio} from 'interfaces/negocio.interface';
import {MetaDataService} from 'src/app/services/meta-data.service';
@Injectable({providedIn: 'root'})
export class PerfilUsuarioResolver {
	constructor(private metaDataServ: MetaDataService, private helpers: HelperService) {}

	public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			this.helpers.showSpinner('cargando');
			const identicador = route.paramMap.get('identificador');
			if (!identicador) {
				return;
			}
			const negocio = await this.helpers
				.obtenerDocumentos('negocios', [], where('identificador', '==', `@${identicador.toLowerCase()}`))
				.then((value) => (value.length > 0 ? (value[0] as Negocio) : null));
			console.log(negocio, 'negocio');
			if (!negocio) {
				return;
			}
			await this.setMetaData(negocio);
			return negocio;
		} catch (err) {
			console.log(err);
			return;
		} finally {
			this.helpers.hideSpinner('cargando');
		}
	}

	private async setMetaData(negocio: Negocio) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const metaData = await this.metaDataServ.getMetaData(negocio.id!);
		if (metaData) {
			this.helpers.setTitle(metaData.title);
			this.helpers.setMetaTags([
				{name: 'description', content: metaData.description},
				{name: 'og:description', content: metaData.description},
				{name: 'og:url', content: `${metaData.url}`},
				{name: 'og:title', content: metaData.title},
				{name: 'og:image', content: negocio.photo},
				{name: 'og:locale', content: 'es_ES'},
				{name: 'og:type', content: 'website'}
			]);
		} else {
			this.helpers.setTitle(`AppFenix | ${negocio.name}`);
			this.helpers.setMetaTags([
				{name: 'description', content: negocio.descripcion},
				{name: 'og:description', content: negocio.descripcion},
				{name: 'og:url', content: `https://app-fenix.com/${negocio.identificador.split('@')[1]}`},
				{name: 'og:title', content: negocio.name},
				{name: 'og:image', content: negocio.photo},
				{name: 'og:locale', content: 'es_ES'},
				{name: 'og:type', content: 'website'}
			]);
		}
		return;
	}
}

