import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, afterRender, inject} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {where} from '@angular/fire/firestore';
import {HelperService} from 'helpers/helper.service';
import {Carrito, ProductoCarrito} from 'interfaces/carrito.interface';
import {Property} from 'interfaces/helper.interface';
import {UserService} from './services/user.service';
import {User as FirebaseUser} from '@angular/fire/auth';
import {Subject, Subscription, take, takeUntil} from 'rxjs';
import {CreateAccountDialogPage} from './pages/index/dialog/create-account-dialog/create-account-dialog.page';
import {LoginAccountDialogPage} from './pages/index/dialog/login-account-dialog/login-account-dialog.page';
import {Tienda} from 'interfaces/tienda.interface';
import {ShopCartController} from './controllers/shopcart.controller';
import {AuthService} from './services/auth.service';
import {TiendaService} from './services/tienda.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {isPlatformBrowser} from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	host: {ngSkipHydration: 'true'}
})
export class AppComponent implements OnInit, OnDestroy {
	/** ref del drawer */
	@ViewChild('drawerLeft') drawerLeft!: MatDrawer;
	@ViewChild('drawerRight') drawerRight!: MatDrawer;
	@ViewChild('miTienda') miTiendaRef!: OverlayPanel;

	loggedUser!: FirebaseUser | null;

	tiendas!: Tienda[];
	selectedTienda?: Tienda;

	carrito: Carrito[] = [];
	activatedBannerCookies = true;
	haveNegocio!: boolean;
	private readonly shopCtrl: ShopCartController = inject(ShopCartController);
	public currentRoute!: string;
	private destroyer = new Subject<void>();

	//prettier-ignore
	constructor(
    public platform: Platform,
    public router: Router,
    private userService: UserService,
    private helpers: HelperService,
    private auth: AuthService,
    private tiendaServ: TiendaService,
	@Inject(PLATFORM_ID) private platformId: Object
  ) {
	afterRender(() => {
			//Verifica si existe la propiedad para quitar el anuncio de las cookies.
			if (localStorage.getItem('acceptedCookies')) {
				this.activatedBannerCookies = false;
			}
	});

  }

	initRouterEvents() {
		this.router.events.pipe(takeUntil(this.destroyer)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = event.url;
			}
		});
	}

	public async ngOnInit(): Promise<void> {
		this.showToolbar();
		this.initUser();

		this.haveNegocio = await this.tiendaServ.tieneNegocio();
		this.initRouterEvents();
	}

	public ngOnDestroy(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.destroyer.next();
		}
	}

	/**
	 * Establece la ruta de las tiendas.
	 * @param  {string} identificador
	 */
	public ruta = (identificador: string | undefined) => (identificador ? `/${identificador?.substring(1)}` : undefined);
	/**
	 * Muestra el toolbar dependiendo de la ruta.
	 * @param routes
	 * @returns
	 */
	public showToolbar = (): boolean => {
		const ruta = this.router.url;
		//Ruta especial
		if (ruta.startsWith('/login-account;')) {
			return false;
		} else {
			return (
				(<Property<boolean>>{
					'/create-account': false,
					'/login-account': false,
					'/': true
				})[ruta] ?? true
			);
		}
	};

	/**
	 * Oculta el logo en las paginas especificadas.
	 * @returns boolean
	 */
	public showLogoAndSearchbar = (): boolean =>
		(<Property<boolean>>{
			'/': false
		})[this.router.url] ?? true;

	/**
	 * Redirige hasta un enlace.
	 * @param link
	 */
	public goTo = (link: string) => {
		this.router.navigate([`/${link}`]);
		this.drawerLeft.toggle(false);
		this.drawerRight.toggle(false);
	};

	/**
	 * Cierra la sesion del usuario logueado
	 * @returns
	 */
	public cerrarSesion = async () => {
		this.drawerLeft.toggle(false);
		this.drawerRight.toggle(false);
		await this.userService.cerrarSesion();
		this.helpers.successMsg('Se ha cerrado la sesión.');
		await this.router.navigate(['/']).then((res: boolean) => window.location.reload());
	};

	/**
	 * Abre la ventana mostrar el horario.
	 * @returns Subscription
	 */
	public openCrearCuenta = (): Subscription =>
		this.helpers.openDialog({
			titulo: '',
			formGroup: '',
			component: () => CreateAccountDialogPage,
			style: 'dialogWindowCrearCuenta',
			closeManual: true
		});

	/**
	 * Abre un dialogo para loguearse dentro del sistema.
	 * @returns Subscription
	 */
	public openEntrarCuenta = (cerrarVentana: any) => {
		() => cerrarVentana();

		this.helpers.openDialog({
			titulo: '',
			formGroup: '',
			component: () => LoginAccountDialogPage,
			style: 'dialogWindowEntrarCuenta',
			closeManual: true
		});
	};

	/**
	 * Acepta todas las cookies de la pagina.
	 */
	public acceptCookies = () => {
		this.activatedBannerCookies = !this.activatedBannerCookies;
		localStorage.setItem('acceptedCookies', 'true');
	};

	/**
	 * Direcciona hacia la tienda y cierra el menu.
	 * @param  {string} direccion
	 * @param  {any} tienda
	 */
	public mover = (direccion: string, tienda: any) => {
		this.goTo(direccion);
		() => tienda();
	};

	/**
	 * Cambia la cantidad de productos en el carrito.
	 * @param {Carrito} producto
	 * @returns
	 */
	public setCantidad = (producto: ProductoCarrito) => this.shopCtrl.setCantidad(producto);

	/**
	 * Elimina el producto del carrito de compras.
	 * @param producto
	 * @returns
	 */
	public eliminarProducto = (indexTienda: number, producto: ProductoCarrito, negocio_id: string) =>
		this.shopCtrl.deleteProduct({uid: this.loggedUser?.uid!, negocio_id: negocio_id, producto: producto, indexTienda: indexTienda});

	/**
	 * Vacía solamente la tienda seleccionada.
	 * @param {number} indexTienda
	 */
	public vaciarCanasta = (indexTienda: number) =>
		this.templateVaciar('¿Desea borrar toda la tienda?', async () => {
			const id: string = this.helpers.OneWayBinding(this.carrito[indexTienda].id);

			await this.helpers.delDoc({
				collection: 'users',
				uid: this.loggedUser?.uid,
				pathSegments: ['carrito', id],
				data: null
			});
		});

	/**
	 * Vacía todo el carrito de los productos
	 */
	public vaciarCarrito = () =>
		this.templateVaciar('¿Desea borrar todo el carrito de compras?', async () => {
			let carrito: Carrito[] = this.helpers.OneWayBinding(this.carrito);

			for (const tienda of carrito) {
				await this.helpers.delDoc({
					collection: 'users',
					uid: this.loggedUser?.uid,
					pathSegments: ['carrito', tienda.id],
					data: null
				});
			}
		});

	/**
	 * Plantilla de confirmacion para vaciar el carrito o la tienda.
	 * @param {string} titulo Titulo de la ventana
	 * @param {Function} funcion Funcion a ejecutar de ser afirmativo.
	 * @returns
	 */
	public templateVaciar = (titulo: string, funcion: Function) =>
		this.helpers.customConfirm({
			message: titulo,
			header: 'Confirmación',
			key: 'confirmEliminar',
			accept: async () => await funcion(),
			acceptLabel: 'Si'
		});

	/**
	 * Muestra el carrito de la tienda especificada.
	 * @param {string} negocio_id
	 */
	public verCarrito = (negocio_id: string) => {
		this.router.navigate([`/carrito/${negocio_id}`]);
		this.drawerRight.toggle();
	};

	public closeDrawer = (toggle: string) => {
		if (toggle === 'right') {
			this.drawerRight.toggle();
			this.drawerLeft.toggle(false);
		} else {
			this.drawerRight.toggle(false);
			this.drawerLeft.toggle();
		}
	};

	onPressLogo() {
		console.log('current route ');
		if (this.selectedTienda) {
			this.goTo('/tienda/perfil');
		} else {
			this.goTo('/');
		}
	}

	/**
	 * Inicializa el usuario logueado.
	 * @returns
	 */
	private initUser = async () =>
		this.userService
			.initUser()
			.pipe(take(1))
			.subscribe(async (res: any) => {
				console.log(res);
				if (res) {
					this.loggedUser = res;
					this.carrito = this.shopCtrl.carrito;
					await this.initNegocio();
					this.tiendaServ.initNegocio(res.uid);
				} else {
					this.tiendaServ.currentNegocio = null;
				}
			});

	/**
	 * Inicializa el negocio del usuario.
	 * @returns
	 */
	private initNegocio = async () =>
		await this.helpers.getInfo('negocios', 'tiendas', where('uid', '==', this.loggedUser?.uid)).then((tiendas: Tienda[]) => {
			this.tiendas = tiendas;
			this.selectedTienda = tiendas[0];
		});
}

