import {inject, Injectable} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {
	FacebookAuthProvider,
	getRedirectResult,
	GoogleAuthProvider,
	signInWithPopup,
	signInWithRedirect,
	signOut,
	UserCredential,
	User as FirebaseUser
} from '@angular/fire/auth';
import {Auth, authState} from '@angular/fire/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Observable} from 'rxjs';
import {httpsCallable, HttpsCallable, Functions} from '@angular/fire/functions';
import {User} from 'interfaces/user.interface';
import {Property} from 'interfaces/helper.interface';
import {where} from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public user!: FirebaseUser | null;
	private googleProvider: GoogleAuthProvider = new GoogleAuthProvider();
	private facebookProvider: FacebookAuthProvider = new FacebookAuthProvider();
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	//prettier-ignore
	constructor(
		private auth: Auth,
		private functions: Functions,
		private helpers: HelperService,
	) {
		this.googleProvider.addScope('email');
		this.facebookProvider.addScope('email');
		this.auth.useDeviceLanguage();

	}

	/**
	 * Obtiene el usuario logueado actualmente.
	 * @returns {Promise<any>}
	 */
	public getUser = async (): Promise<FirebaseUser | null> => await firstValueFrom(this.getUser$());

	/**
	 * Obtiene el usuario logueado actualmente con un Observable.
	 * @returns
	 */
	public getUser$ = (): Observable<FirebaseUser | null> => authState(this.auth);

	//= () => authState(this.auth);

	/**
	 * Obtiene el token del usuario logueado.
	 * @returns
	 */
	public getUserToken = async (): Promise<string | undefined> =>
		await this.getUser().then(async (user: FirebaseUser | null) => user?.getIdToken());

	/**
	 * Devuelve el usuario si existe dentro de Firebase.
	 * @returns
	 */
	public compareExistence = async () =>
		await this.getUser().then(async (user: any) =>
			user ? await this.helpers.obtenerDocumento('users', user.uid).then((user) => (user ? user : undefined)) : undefined
		);

	/**
	 * Verifica si el usuario tiene tienda.
	 * @param {string} modulo Nombre del paso para crear la tienda
	 * @returns
	 */
	public checkIfTiendaExists = async (modulo?: string) =>
		this.getUser()
			.then(
				async (googleUser: FirebaseUser | null) =>
					await this.helpers.getInfo('negocios', 'confirmData', where('uid', '==', googleUser?.uid))
			)
			.then((confirmData: any) => (modulo ? confirmData[0][modulo] : confirmData[0]));
	/**
	 * Registra o loguea un usuario con Google.
	 */
	public googleCreateLoginAccount = async (ruta?: string) =>
		await this.helpers
			.showSpinner('cargando')
			.then(() => signInWithPopup(this.auth, this.googleProvider).then(async (result: UserCredential) => this.baseLogIn(result, ruta)))
			.finally(() => this.helpers.hideSpinner('cargando'));

	public googleLoginRedirect = async () =>
		await this.helpers
			.showSpinner('cargando')
			.then(() => signInWithRedirect(this.auth, this.googleProvider).finally(() => this.helpers.hideSpinner('cargando')));

	public redirectGoogleSign = async (ruta?: string) =>
		await this.helpers
			.showSpinner('cargando')
			.then(() => getRedirectResult(this.auth))
			.then((result) => {
				console.log(result);
				if (!result) {
					return null;
				}
				return result ? this.baseLogIn(result, ruta) : null;
			})
			.catch((err) => console.error(err))
			.finally(() => this.helpers.hideSpinner('cargando'));

	/**
	 * Registra o loguea un usuario con Facebook.
	 */
	public facebookCreateLoginAccount = async (ruta?: string) =>
		await this.helpers.showSpinner('cargando').then(() =>
			signInWithPopup(this.auth, this.facebookProvider)
				.then(async (result: UserCredential) => this.baseLogIn(result, ruta))
				.finally(() => this.helpers.hideSpinner('cargando'))
		);

	/**
	 * Generico para iniciar sesion.
	 * @param result
	 */
	private baseLogIn = async (result: UserCredential, ruta?: string) =>
		await this.searchUserInDb(result.user?.uid!)
			.then((user: User[]) =>
				(<Property<Function>>{
					true: () => {
						this.redirect(true, 'Bienvenido.', ruta ? ruta : '/');
						return true;
					},
					false: (user: UserCredential['user']) => {
						this.helpers.setDoc({
							collection: 'users',
							uid: user?.uid,
							pathSegments: [],
							data: {
								email: user?.email,
								firstname: user?.displayName,
								lastname: '',
								photo: user?.photoURL,
								uid: user?.uid,
								privilegios: {
									agregarProducto: false,
									habilitarCategoria: false,
									editarProducto: false,
									editarCategoria: false,
									editarUsuario: false,
									editarTienda: false,
									agregarCarrito: false,
									mensaje: true,
									compartir: false,
									cambiarDireccion: false
								}
							}
						});

						this.redirect(true, 'Cuenta creada.', ruta ? ruta : '/');
						return true;
					}
				})[Boolean(user[0]).toString()](result.user)
			)
			.catch((error: any) => {
				this.redirect(false, error, '/');
				return false;
			});

	/**
	 * Redirecciona al usuario a la tienda.
	 * @param {string} message
	 */
	private redirect = (success: boolean, message: string, direccion?: string) => {
		success ? this.helpers.successMsg(message) : this.helpers.errorMsg(message);
		this.router.navigate([direccion ?? '/tienda']);
	};

	/**
	 * Buscamos al usuario en Firestore
	 * @param {string} id ID de usuario
	 * @returns {Promise<User[]>} Retorna cuando el usuario es encontrado.
	 */
	public searchUserInDb = async (uid: string): Promise<User[]> =>
		this.helpers.obtenerDocumento('users', uid).then((user: User) => (user ? [user] : [])); // await this.helpers.getInfo('users', 'all', where('uid', '==', uid));

	/**
	 * Removemos al usuario de la Auth
	 * @param uid Id de usuario
	 * @returns void
	 */
	public removeUser = async (uid: string, callable: HttpsCallable = httpsCallable(this.functions, 'removeUserFromAuthenticar')) =>
		await callable({uid: uid});

	/**
	 * Cerramos la sesion en la app
	 * @returns void
	 */
	public logout = () => signOut(this.auth).catch((error: any) => this.helpers.firebaseErrors({error: error}));
}

