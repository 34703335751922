/*eslint-disable*/
import {HttpClient} from '@angular/common/http';
import {OnInit, inject} from '@angular/core';
import {DocumentChange, DocumentData, QuerySnapshot, arrayRemove, limit, where} from '@angular/fire/firestore';
import {HelperService} from 'helpers/helper.service';
import {Carrito, ProductoCarrito} from 'interfaces/carrito.interface';
import {Negocio} from 'interfaces/negocio.interface';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject, Subscription, firstValueFrom, map} from 'rxjs';
import {User as FirebaseUser, Unsubscribe} from '@angular/fire/auth';
import {AuthService} from '../services/auth.service';
import {Product} from 'interfaces/product.interface';
import {ObserverController} from './observer.controller';
import {Property} from 'interfaces/helper.interface';
import {Factura} from 'interfaces/factura.interface';
import {Cotizacion} from 'interfaces/cotizacion.interface';

export class ShopCartController {
	private readonly helpers = inject(HelperService);
	private readonly spinner = inject(NgxSpinnerService);
	private readonly http = inject(HttpClient);
	private readonly auth = inject(AuthService);
	private readonly obsCtrl = inject(ObserverController);
	private carritoBehavior = new BehaviorSubject<Carrito[]>([]);
	suscription!: Subscription;
	unsubscribe!: Unsubscribe;
	loggedUser!: FirebaseUser;
	carrito: Carrito[] = [];
	productosCarrito: ProductoCarrito[] = [];
	factura: Factura = {
		cantidad: 0,
		precio: 0,
		total: 0
	};

	//prettier-ignore
	constructor(){
    this.initUser();
  }

	public getCarrito(negocioId: string) {
		return this.carritoBehavior.pipe(map((carrito: Carrito[]) => carrito.filter((tienda: Carrito) => tienda.id === negocioId)));
	}

	/**
	 * Inicializa el usuario logueado.
	 * @returns
	 */
	private initUser = async () =>
		this.auth.getUser$().subscribe(async (res: any) => {
			if (this.unsubscribe) {
				this.unsubscribe();
			}

			if (res) {
				this.loggedUser = res;
				this.initObserver();
			}
		});

	/**
	 * Inicializa el observable de Firebase.
	 * @returns
	 */
	private initObserver = () => (this.unsubscribe = this.obsCtrl.obtenerCarrito(this.loggedUser?.uid!, this.nextCarritoObserver));

	/**
	 * Observador de los productos en el carrito
	 * Leyenda: arg[0] => producto: ProductoCarrito, arg[1] => indexTienda: number, arg[2] => indexProducto: number
	 * @param {QuerySnapshot<DocumentData>} querySnapshot Captura de los cambios en los productos del carrito
	 */
	private nextCarritoObserver = async (querySnapshot: QuerySnapshot<DocumentData>): Promise<void> =>
		querySnapshot.docChanges().forEach((change: DocumentChange) =>
			(<Property<Function>>{
				added: () => {
					let carrito = change.doc.data() as Carrito;
					this.carrito.push(carrito);
					this.factura = this.calcularFactura();
					this.carritoBehavior.next(this.carrito);
				},
				modified: () => {
					let carrito = change.doc.data() as Carrito;
					const indexTienda = this.carrito.findIndex((tienda: Carrito) => tienda.id === carrito.id);

					this.carrito[indexTienda].cotizacion = carrito.cotizacion;
					this.carrito[indexTienda].productos = carrito.productos;
					this.carrito[indexTienda].factura = this.calcularCotizacion(carrito.productos);
					this.carritoBehavior.next(this.carrito);
				},
				removed: () => {
					let carrito = change.doc.data() as Carrito;
					const indexTienda = this.carrito.findIndex((tienda: Carrito) => tienda.id === carrito.id);
					this.carrito.splice(indexTienda, 1);
					this.factura = this.calcularFactura();
					this.carritoBehavior.next(this.carrito);
				}
			})[change.type]()
		);

	/**
	 * Agrega el producto al carrito de compras
	 * @returns
	 */
	public addCarrito = async (negocio: Negocio, producto: Product, cantidad: number) => {
		//Se busca el indice la tienda
		const indexTienda = this.carrito.findIndex((carrito: Carrito) => carrito.id === negocio.id);

		//Si existe la tienda se asignan los productos de la tienda.
		this.productosCarrito = this.carrito[indexTienda]?.productos ?? [];

		//Se busca el indice del producto en la lista de productos de la tienda en el carrito.
		const indexProducto = this.productosCarrito?.findIndex((productoC: ProductoCarrito) => productoC.id === producto.id);

		//Agrega un producto nuevo al carrito.
		const addNewProduct = () => this.productosCarrito.push({...producto, cantidad: cantidad});

		//Suma un producto mas a uno ya existente en el carrito.
		const plusProduct = () =>
			(this.productosCarrito[indexProducto] = {...producto, cantidad: this.productosCarrito[indexProducto].cantidad! + cantidad});

		//Si el indice existe se agrega uno mas sino se agrega uno nuevo.
		indexProducto !== -1 ? plusProduct() : addNewProduct();

		//Se calcula la factura total de los productos.
		this.factura = this.calcularCotizacion(this.productosCarrito);

		//Se agrega a la base de datos.
		await this.helpers.setDoc({
			collection: 'users',
			uid: this.loggedUser.uid,
			pathSegments: ['carrito', negocio.id!],
			data: {
				id: negocio.id!,
				tienda: negocio.name,
				identificador: negocio.identificador,
				photo: negocio.photo,
				telefono: this.getPhone(negocio),
				factura: this.factura,
				cotizacion: null,
				productos: this.productosCarrito,
				negocio: negocio
			}
		});

		this.helpers.successMsg('Producto agregado al carrito.');
	};

	/**
	 * Cambia la cantidad de productos en el carrito.
	 * @param {ProductoCarrito} producto
	 * @returns
	 */
	public setCantidad = (producto: ProductoCarrito) => {
		const indexTienda = this.carrito.findIndex((tienda: Carrito) => tienda.id === producto.negocio_id!);
		const indexProducto = this.carrito[indexTienda].productos.findIndex(
			(productoCarrito: ProductoCarrito) => productoCarrito.id === producto.id
		);

		this.carrito[indexTienda].productos[indexProducto] = producto;

		this.helpers.setDoc({
			collection: 'users',
			uid: this.loggedUser.uid!,
			pathSegments: ['carrito', producto.negocio_id!],
			data: {cotizacion: null, productos: this.carrito[indexTienda].productos}
		});
	};

	/**
	 * Elimina el producto del carrito de compras.
	 * @param {Doc} Params
	 * @returns
	 */
	public deleteProduct = async (Params: {uid: string; negocio_id: string; producto: ProductoCarrito; indexTienda: number}) => {
		const indexTienda = this.carrito.findIndex((tienda: Carrito) => tienda.id === Params.negocio_id);

		if (this.carrito[indexTienda].productos.length === 1) {
			await this.helpers.delDoc({
				collection: 'users',
				uid: this.loggedUser?.uid,
				pathSegments: ['carrito', Params.negocio_id],
				data: null
			});

			return;
		} else {
			this.helpers.setDoc({
				collection: 'users',
				uid: Params.uid,
				pathSegments: ['carrito', Params.negocio_id],
				data: {cotizacion: null, productos: arrayRemove(Params.producto)}
			});
		}
	};

	/**
	 * Obtiene el numero de telefono del negocio.
	 * @param negocio
	 * @returns
	 */
	public getPhone = (negocio: Negocio) => (negocio.tel.code && negocio.tel.num ? `${negocio.tel.code}${negocio.tel.num}` : '');

	/**
	 * Obtiene la cotizacion desde el middleware.
	 * @param params
	 * @returns
	 */
	public obtenerCotizacion = async (params: any): Promise<any> =>
		await firstValueFrom(this.http.post(`${this.helpers.middleware('prod')}/cotizacion/`, params)).catch((error: any) => {
			this.helpers.firebaseErrors({error: error});
			this.spinner.hide('generando');
		});

	/**
	 * Descarga un archivo en formato PDF>
	 * @param {ProgressEvent<FileReader>} event
	 */
	public dispatchEvent = (event: any, nombre: string = 'archivo.pdf') => {
		//Usaremos un link para iniciar la descarga
		const save = document.createElement('a');
		save.href = (event.target?.result as string) ?? event;
		save.target = '_parent';

		//Aquí le damos el nombre al archivo
		//Sin esta propiedad el archivo se muestra en una ventana aparte.
		/* save.download = nombre; */
		const clicEvent = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true
		});

		//Simulamos un clic del usuario no es necesario agregar el link al DOM.
		save.dispatchEvent(clicEvent);

		//Y liberamos recursos...
		(window.URL || window.webkitURL).revokeObjectURL(save.href);
	};

	/**
	 * Genera un numero de control para la cotización.
	 * @returns {Promise<number>}
	 */
	public numeroCotizacion = async (): Promise<number> => (await this.helpers.obtenerDocumento('cotizaciones', 'contador')).nro + 1;

	/**
	 * Descarga la cotizacion desde el carrito.
	 * Uso: carrito.page.ts
	 * @param {Carrito} carrito La tienda.
	 */
	public descargarPDF = async (carrito: Carrito) => {
		this.spinner.show('generando');

		if (carrito.cotizacion) {
			const id = carrito.cotizacion.split('/')[4];
			const cotizacion = await this.helpers.obtenerDocumentos('cotizaciones', [], where('id', '==', id), limit(1));

			return await this.createPDF(cotizacion[0], carrito, true);
		}

		const pdf: Cotizacion = await this.cuerpoPDF(carrito);

		return await this.createPDF(pdf, carrito, false);
	};

	/**
	 * Crea y envia un enlace del archivo PDF al Whatsapp.
	 * Uso: carrito.page.ts
	 * @param pdf
	 * @param carrito
	 */
	public enviarCotizacion = async (carrito: Carrito) => {
		this.spinner.show('generando-enlace');

		const id = this.helpers.crearId('cotizaciones');
		const enlace = `https://app-fenix.com/cotizacion/${id}`;
		const pdf: Cotizacion = await this.cuerpoPDF(carrito);

		await this.helpers.setDoc({collection: 'cotizaciones', uid: id, pathSegments: [], data: {...pdf, id: id, uid: this.loggedUser.uid}});
		await this.helpers.setDoc({
			collection: 'users',
			uid: this.loggedUser.uid,
			pathSegments: ['carrito', carrito.id],
			data: {cotizacion: enlace}
		});

		//Contador
		await this.helpers.setDoc({collection: 'cotizaciones', uid: 'contador', pathSegments: [], data: {nro: pdf.nroCotizacion}});
		await this.spinner.hide('generando-enlace');

		return {enlace, telefonoNegocio: pdf.telefonoNegocio};
	};

	/**
	 * Descarga directamente una cotizacion usando su id.
	 * Uso: cotizacion.page.ts
	 * @param id
	 * @returns
	 */
	public descargaCotizacion = async (id: string) => {
		this.spinner.show('generando');
		const pdf = await this.helpers.obtenerDocumento('cotizaciones', id);

		//this.carrito[0] está de adorno. No borrar.
		return await this.createPDF(pdf, this.carrito[0], true);
	};

	/**
	 * Genera el archivo PDF.
	 * @param pdf
	 * @param carrito
	 * @param conEnlace
	 * @returns
	 */
	public createPDF = async (pdf: Cotizacion, carrito: Carrito, conEnlace: boolean) =>
		await this.obtenerCotizacion(pdf).then(async (res: any) => {
			const blob = new Blob([new Uint8Array(res.buffer.data).buffer], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);

			if (!conEnlace) {
				const id = this.helpers.crearId('cotizaciones');
				await this.helpers.setDoc({
					collection: 'cotizaciones',
					uid: id,
					pathSegments: [],
					data: {...pdf, id: id, uid: this.loggedUser.uid}
				});
				await this.helpers.setDoc({
					collection: 'users',
					uid: this.loggedUser.uid,
					pathSegments: ['carrito', carrito.id],
					data: {cotizacion: `https://app-fenix.com/cotizacion/${id}`}
				});
				await this.helpers.setDoc({collection: 'cotizaciones', uid: 'contador', pathSegments: [], data: {nro: pdf.nroCotizacion}});
			}

			this.spinner.hide('generando');
			return url;
		});

	/**
	 * Informacion que solicita el middleware para generar el PDF.
	 * @param carrito
	 * @returns
	 */
	private cuerpoPDF = async (carrito: Carrito): Promise<Cotizacion> => ({
		nroCotizacion: await this.numeroCotizacion(),
		fechaCotizacion: await this.helpers.fechaActual(),
		nombreNegocio: carrito.tienda,
		telefonoNegocio: carrito.telefono,
		nombreCliente: this.loggedUser.displayName!,
		productos: carrito.productos
	});

	/**
	 * Calcula el precio de los productos
	 * @param  {Factura} factura
	 */
	public calcularFactura = (factura: Factura = {cantidad: 0, precio: 0, total: 0}): Factura =>
		this.carrito.map((carrito: Carrito) => {
			const cotizacion: Factura | undefined = this.calcularCotizacion(carrito.productos);
			factura = {
				cantidad: factura.cantidad + cotizacion?.cantidad,
				precio: factura.precio + cotizacion?.precio,
				total: factura.total + cotizacion?.total
			};
		}) && factura;

	/**
	 * Calcula la factura mostrada en la cotizacion.
	 * @param {ProductoCarrito[]} productos
	 * @param {Factura} factura
	 * @returns {Factura}
	 */
	public calcularCotizacion = (productos: ProductoCarrito[], factura: Factura = {cantidad: 0, precio: 0, total: 0}): Factura =>
		productos?.map((producto: ProductoCarrito) => {
			//Se agrega un producto nuevos
			factura.cantidad += 1;

			//Se calcula el precio.
			factura.precio += producto.precio * producto.cantidad!;

			//Se estima el total.
			/* const total = precio; */

			//Factura final.
			factura = {cantidad: factura.cantidad, precio: factura.precio, total: factura.precio};
		}) && factura;
}

